<template>
  <quill-editor
    v-model="content"
    :options="snowOption"
  >
    <div
      id="toolbar"
      slot="toolbar"
    >
      <!-- Add a bold button -->
      <button class="ql-bold">
        Bold
      </button>
      <button class="ql-italic">
        Italic
      </button>
      <button class="ql-underline">
        Underline
      </button>
      <button class="ql-strike">
        Strike
      </button>

      <button class="ml-1 ql-blockquote">
      </button>

      <button class="ml-1 ql-header" value="1">
      </button>        
      <button class="ql-header" value="2">
      </button>
      
      <button class="ml-1 ql-list" value="ordered">
      </button>        
      <button class="ql-list" value="bullet">
      </button>

      
      <button class="ml-1 ql-indent" value="-1">
      </button>        
      <button class="ql-indent" value="+1">
      </button>



      <!-- Add font size dropdown -->
      <select class="ql-size">
        <option value="small">Petit</option>
        <!-- Note a missing, thus falsy value, is used to reset to default -->
        <option selected >Normal</option>
        <option value="large">Grand</option>
        <option value="huge">Très grand</option>
      </select>

      <select class="ql-font">
        <option selected="selected"> Normal </option>
        <option value="serif" />
        <option value="monospace" />
      </select>
      
      <span class="ml-1 ql-formats">
        <select class="ql-color">
        </select>
        <select class="ql-background">
        </select>
      </span>
    </div>
  </quill-editor>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
        placeholder: 'Saississez votre texte',
        modules: {
          toolbar: "#toolbar"
        }
      }
    }
  }
}
</script>